import type { SxProps } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

interface LinkedInIconProps {
  sx: SxProps;
}
const LinkedinIcon = ({ sx }: LinkedInIconProps) => (
  <SvgIcon sx={sx}>
    <svg
      version="1.1"
      id="svg450"
      width="203.2"
      height="172.8"
      viewBox="0 0 203.2 172.8"
      xmlns-xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-svg="http://www.w3.org/2000/svg"
    >
      <defs id="defs454" />
      <g id="g456">
        <image
          width="203.2"
          height="172.8"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAnsAAAIcCAYAAACOxWh1AAAACXBIWXMAAC4jAAAuIwF4pT92AAAg
AElEQVR4nO3dzXEbSZ7w4ZyJvkuvBdTeFidxLBDHAITogajT1m3YFohtwbBvuA1lwVILA5qyYMgT
jktZsKIF/UZSCQnCB4mPKqDqX88TgdAsyZZQBcXqF5mVmX/5888/0yENqvHLlNJxeb0sr+ODvikA
6J6vKaXbmV9vJ6PhV58je4+9QTV+lVI6mXkdLfwQAFCHLyX8rlNKN5PR8N5d7Z+9xF4ZvTsrr9cL
PwAA7MNdSukqv4z69UejsTeoxnk69jyl9G7hmwDAIX0s0XfjU4itkdgbVOM8PXuRUnqz8E0AoE0+
53+zRV9ctcZeeR7vwkgeAHTOpzwb57m+eP5a1xUNqvF5eQhU6AFA97zN/44PqvGFzy6WnUf2ymje
lSlbAAgjL+Q4NcoXw04je4NqfFpG84QeAMTxuozynfpMu2/r2CvTtv+dUnqx8E0AoOvyv+//Xf69
p8O2msYdVOMrz+YBQG98nIyGZz7ubtp4ZE/oAUDvvCv//tNBG8We0AOA3hJ8HbV27A2q8aXQA4Be
E3wdtNYze4NqnOfp/7XwDQCgj95PRkPR1xHPxl453/bfC98AAPrsb5PR8NbfgPZ7chp3UI1fppSu
F74BAPTddekEWu7J2Cvn3B4tfBUA6LujcoIWLbdyGndQjU9SSn8sfAMA4Ie/T0bDG/ejvZ4a2VPr
AMBz9ELLLY29QTU2fQsArOOodAMttTCNWx62vHfmLQCwpoeU0qvJaPjVDWufZSN750IPANjAi9IP
tNCq2AMA2MS5rVja6afYKydlGNUDADaV++HUXWuf+ZG9s77fEABga2YHW+j7Ao1BNX6VUvrfvt8Q
AGAn/zEZDe/dwvaYHdkz9AoA7EpPtIzYAwDq5JGwlnmcxi2rZ/6v7zcDAKjF/7PnXntMR/aO+34j
AIDanLiV7TGNPR8KAFAXg0gtYmQPAKibQaQWmcbeq77fCACgNrqiRaax97rvNwIAqM2RW9kef3WO
HQBQt3JYAy3wV8/rAQANEHstMX82LgAAgYg9AIDAxB4AQGBiDwAgMLEHABCY2AMACEzsAQAEJvYA
AAITewAAgYk9AIDAxB4AQGBiDwAgMLEHABCY2AMACEzsAQAEJvYAAAITewAAgYk9AIDAxB4AQGBi
DwAgMLEHABCY2AMACEzsAQAEJvYAAAITewAAgYk9AIDAxB4AQGBiDwAgMLEHABCY2AMACEzsAQAE
JvYAAAITewAAgYk9AIDAxB4AQGBiDwAgMLEHABDYLz7czvucUrqfez3lOKX0MqX0qrzePPGzAEDH
ib1u+ZJSuimv28loeLvFu7+Z/8KgGucAPCkheJpSerHwXwEAnST22i8H3nVK6WrLuHtW+X2//94l
/s5K+B314zYDQExir70+lsBbGIlrWom/8/waVOPTEn5v+/6BAEAXib12eUgpXebXZDT82oZ3NhkN
86ji9aAa5+f7LkzzAkC3iL12aF3kzZuMhnnhx9mgGr8s7/Xdwg8BAK0j9g4vT9delJhqvRKjOfry
KN+V1bwA0G5i73DywouzQzyTV4cSpyflmb4rU7sA0E42VT6M3/M2J10NvVnlmb78PN+nhW8CAAdn
ZG+/Hspo3nWkiypTu6eDapxX8P5z4QcAgIMxsrc/d3naM1rozZqMhnnhxt9K1AIALSD29mMaeo1s
itwm5RpflWsGAA5M7DXv42Q0PG7rlipNKNd6IvgA4PDEXrNy6J1FvsBVBB8AtIPYa05vQ29K8AHA
4Ym9Ztz1PfSmBB8AHJbYq99diRuKmeCzShcA9kzs1Wu6j15vFmOsayb4AIA9Env1OuvD9irbKvfm
126+ewDoJrFXn98jb5hcl7LxsqPVAGBPxF498nN6FxEuZE/OPL8HAPsh9upx7jm99ZV7ZbUyAOyB
2Ntd3k/vpusXsW9lyvtzv64aAPZP7O0mT0Wed/kCDszoHgA0TOzt5tL07fYmo+F9XtjS1fcPAF0g
9raXR/Uuu/rmW+TCYg0AaI7Y255RvRqUe3jV+QsBgJYSe9sxqlcv9xIAGiL2tnNlVK8+5dm9j1Gu
BwDaROxtx0hU/Zw+AgANEHubuysjUdSo7Lv3xT0FgHqJvc1ZTNAco3sAUDOxtzlB0hwhDQA1E3ub
MYXboMloeGvPPQCol9jbjDNwm2fkFABqJPY2I/aa5x4DQI3E3maESPNuo18gAOyT2FvfFxspN688
twcA1ETsrU+E7M/nvlwoADRN7K1P7O2PFc8AUBOxtz4Bsj/uNQDUROytT4Dsj3sNADURe7SR2AOA
moi99QkQAKBzxN6aHJMGAHSR2AMACEzsAQAEJvYAAAITe2saVOOXnXijAAAzxN76jrvyRgEApsQe
bfTKpwIA9RB76xMg++NeA0BNxN76BMj+uNcAUBOxtz7P7O2P2AOAmoi99QmQ/XnTlwsFgKaJvfW9
7sob7bJBNRbVAFAjsbeBQTU+6cyb7S73GABqJPY2I0Sa5x4DQI3E3mZOu/RmO0rsAUCNxN5mXjs2
rTmDapxXPB9FvT4AOASxtzmje805i3phAHAoYm9zYq857i0A1Ezsbe6t7UHqZwoXAJoh9rZjurF+
59EuCADaQOxtR5jUqCx6eRfmggCgRcTedl4MqrHRvfqIZwBoiNjb3kVX33iblFE9sQcADRF72zsy
uleLHHovAlwHALSS2NvNhU2Wt2dUDwCaJ/Z2cyRWdnJpVA8AmiX2dvfBvnubG1TjEytwAaB5Yq8e
VxEuYl/K9K17BgB7IPbq8WZQja3OXd+F0zIAYD/EXn0+lCO/eMKgGufzb/+x+icAgDqJvXpdW527
Wolh07cAsEdir155avIm0gXVZeY5PatvAWCPxF79Xg+qsdGrRdf53ix8FQBolNhrxjvB90O5F28W
vgEANE7sNUfw/Qg9++kBwIGIvWb1OviEHgAc3i8+g8bl4EuT0fAs+HV+VxZjXJu6BYDDM7K3Hzn4
bvuwLUs5Ou5G6AFAO4i9/ckrUW8jb7xczru9teoWANpD7O1X3ofv3xGPVhtU48uU0h/20QOAdvHM
3mF8KKNgZ5PR8L7LFzJzKobRPABoIbF3OPmZtv8dVOPfUkqXk9Hwa5fefHn+8MI5twDQbqZxD+9D
eZavM6t1yzT0vdADgPYzstcO+Vm+f5WIupiMhq3bm6+M5OUgPS/vFwDoALHXLrPRl4Pv6tDP9JWt
VM5L6Fl8AQAdI/ba6ahM7+aFHJ/KBsXX+3qurwTeaQk8Cy8AoMPEXvu9La884ve5bFh8MxkNb+p6
52WKNq+qPSmRJ/AAIAix1y1vyiuP+OU3flcWStyWXx+nfJeF4EzQZa/K67j8Ku4AICix122vy+vt
7FWUEAQAsPUKAEBkYg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCx
BwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAAC
E3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQ2C8+XICDekgp3W7xBo5TSi8WvgowR+wBNOtzSul+7vV1
MhpuE3hPGlTjVymlV+VnXpYgTOXXl+V7R0/9HkA8Yg+gPjnsbspI3e1kNLzf570tf97sn3m98EM/
R+HxzK9GCiEosQewvWnc3UxGw5uu3MeZKPzpPZcInIbfSUrpzcJ/DHSO2ANY30MZLcuRdD0ZDb9G
unczEfh9RHBQjafhl19vF/4joPXEHsDzPqWUriaj4dJp0cjKs4X5dZm+xV+OvtPy8vwfdIDYA1ju
Swmcq2gjeLso09X5dV5G/c6EH7Sb2Nvdr1tum8C3fyTeBbgPH3MQLHyVVdr+uX8sgdeZZ/AOpYz6
nc+E33kJPws9oEXE3u5u/aOwnTIdFMG9vwPra+nnPn0W72LfK2ijKOGXQz5/xmcl/F73/b5AG4g9
oM8eylTtpana+kxGwzzSfVXCPsoIPnSW2AP66vcykifyGjJ9vm9QjS/yvRZ9cBhiD+ibvLL23HTt
/pR7fSb64DDEHtAXeXXtmecrD2cm+i7L9LlNm2EPxB4Q3UN5Ju/CJ90OZTHHyaAan5bos20LNOiv
bi4QWD7O7FjotVPZpPq4PD8JNMTIHhDVr5PR8NKn225lgUzep++67FdplA9qZmQPiOYupfQ3odct
5VnK47KpNVAjsQdEkkPhpDwTRsfkUb7JaJj35XtfnrUEaiD2gAhyGLzPoWDfvO4rmzKflFFaYEdi
D+i6hzKa53ziQKYrdsu+iMAOxB7QZXnk55Vp25jKtO6p5/hgN2IP6Krp83mmbYMrz/H92vf7ANsS
e0AXffR8Xr+U1dXv+34fYBtiD+iaj2Wkh54pz2UKPtiQ2AO65Feh12+CDzYn9oCueG+jZJLgg42J
PaALfre1CrPK3werdGENYg9ou/yM3rlPiXllSl/wwTPEHtBmFmPwnHMnbcDTxB7QVndCj+eU7XdO
naULq4k9oI3uylFZ8KzJaHhfgg9YQuwBbZNHaGyYzEYmo+FNSuk3dw0WiT2gbc6cdcs2JqPhhef3
YJHYA9rkt8loeO0TYQee34M5Yg9oi89lZAa2Vp7f8/cIZog9oA0en9PzSVCHctLKZzcTvhF7QBuc
lREZqIuNuKEQe8ChffKcHnUri3yszqX3ktgDDsz0LU26tFgDxB5wWPbTozHl75bpXHpP7AGH8tn0
LU2bjIZXKaUvbjR9JvaAQzF9y74Y3aPXfun7DQD2q+ylZx809iaPIA+qcd6K5Y27Th8Z2QOgDy59
yvSV2AMgvPJ8qGf36CWxB0BfeHyAXhJ7APSClbn0ldgDoE+ufNr0jdgDoE8s1KB3xB4AvVFO1fjo
E6dPxB4AfWMql14RewD0ymQ0vLFQgz4RewD0kWf36A2xB0AfXfvU6QuxB0DvTEbD+5TSnU+ePhB7
APSVhRr0gtgDoK9M5dILYg+AXjKVS1+IPQD6zFQu4Yk9APrsxqdPdGIPgN6ajIa3NlgmOrEHQN8Z
3SM0sQdA31mVS2hiD4C+M7JHaGIPgF6bjIZfbcFCZGIPAIzuEZjYAwCxR2BiDwBSunUPiErsAdB7
5eg0++0RktgDgG+M7hHSLz5WgEWDavwypXRcvnGy8APfvpd/5r68Zk2f/7otKz3phvy5vfVZEY3Y
A3pvUI2PS9DlX1+llN5scE+W/eyH6f8YVOOHMmJ0W2LiRgC2lpE9QhJ7QO8MqnEOutMSeE2P5Lwo
QZhf/0jf/vy8p9tVPrmhPCtGC0xGw5tBNfZREI7YA3qhTMvmwDtPKb0+8DXnP/+f+TWoxp9z+E1G
w6uFn+IQ8iKNI3eeSMQeEFoZxbsoofeihdf6OOo3qMb5PV6IvoO7FXtEYzUuEFKOvEE1zuH0vyml
dy0NvVk5MP41qMb3g2q8bEEI++G5PcIxsgeEUqZrz2cXSXRMjr4/BtX4U0rpzGKOvRN7hGNkDwij
jIjddjj0ZuWFI/dlpTD7Y8EM4Yg9IIRBNb7MI2LBnrfKU8//HlTjs4Xv0IjJaGhkj3DEHtBpedp2
UI1vp9uaBPWvsoCD/bhzn4lE7AGdVaY4b1uwlco+fCgLTmie5yQJRewBnVRC76Zn22S8M6W7Fzct
eA9QG7EHdM5M6LV9O5Um5Cnd03iX1SpG9ghF7AGdUjZJ7mvoTV1ZpdsoizQIRewBnVH20Lvueeil
cv2e32uOkT1CEXtAl1z2ZDHGOl5bodsM268QjdgDOmFQjc/LsWf88MF0bmMegl4XPST2gNYrz+kZ
xVruculX2ZXRPcIQe0AXXHlOb6U35Zg4gKXEHtBqZV+5Nz6lJ1msUT8je4Qh9oDWKqtvTd8+78jo
Xu2syCUMsQe02XnPTsjYhSiul9gjDLEHtFIZ1Tv36aztTVnIQj1M4xKG2APa6tyijI2JY2CB2APa
yoH/m3PP6nMf5UJA7AGtU1bgelZvcy8G1fi0a2+6jSajodgjDLEHtJERqu2JPeAnYg9olbLIwL56
2xN7wE/EHtA2YmU3L5yXW5vPQa6DnhN7QNuYwt2dYAa+E3tAa5Qp3Nc+kZ05TQP4TuwBbWJEqh6e
eQS+E3tAm4i9mjgrtxY3Aa4BxB7QKkak6mORBvBI7AGtYCSqdmIPeCT2gLYQe/USe8AjsQe0hdir
l1XNwCOxB7SF5/VqZnPlnTkflxDEHnBwoqQxL4Ne176IPUIQe0AbvPIpNMLUOCD2gFYwstcMI3uA
2ANawQhUM0Q0IPaAVjACBdAQsQe0gW1CmmGFMyD2gMMaVGOLMwAaJPaAQxN7DRpUY1Pk0HNiDzg0
sdcsizSg58QecGhiD6BBYg84NNOMAA0Se8ChmWZslvu7vduuvnGYJfYAYjNyuqXJaPi1k28c5og9
4NDECECDxB5waDZUBmiQ2AMACEzsAcRmmhx6TuwBB+N0h72wGhd6TuwBhyREABom9gAAAhN7AACB
iT0AgMDEHgBAYGIPACAwsQcAEJjYAwAITOwBAAQm9gAAAhN7AACBiT0AgMDEHgBAYGIPACAwsQcA
EJjYAwAITOwBAAQm9gAAAhN7AACBiT0AgMDEHgBAYGIPACAwsQcAEJjYAwAITOwBAAQm9gAAAhN7
AACBiT0AgMDEHgBAYGIPACAwsQcAEJjYAwAITOwBAAQm9gAAAhN7AACBiT0AgMDEHgBAYGIPACAw
sQcAEJjYAwAITOwBAAQm9gAAAhN7AACBiT0AgMDEHgBAYGIPAJYYVOOXi1+F7hF7ALDc8dKvQseI
PQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQ
mNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sA
AIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCx
BwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAAC
E3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8A
IDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2
AAACE3sAAIGJPQCAwMQeAEBgYg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwMQeAEBg
Yg8AIDCxBwAQmNgDAAhM7AEABCb2AAACE3sAAIGJPQCAwP7yn//1PycppT98yFu7Syl97eh7P7RX
KaWjANfxJaV0v/BV1vEypfTanWrUQ0rpNvD1Ncnfz938fTIa3nT5AqL4pe83oAb+HwFHQaKVmF6k
lN74bKG/TOMCAAQm9gAAAhN7AACBiT0AgMDEHgBAYGIPACAwsQcAEJjYAwAIzKbKAABrGlTjfPLY
cTlhZfrrKl/LCTb5lKX7Q50oIvYAAFYYVOMcdGcppZMtT816O/0fg2qcf/mcUsrRdz0ZDfdylKHY
AwCYMajG+ez285TSaQPHYb4prw+DapzPVr/Kr8lo2NgZ62IPAODHFO3FHs+TziH5oYTfp5TSZRNT
vWIPAOi1HSLvc/n1vrymXpVX2uD3zNO9bwfVOP+e53VO8Yo9AKCXBtX4ZZlGfbvG9ecp1+vyvN3t
JtOuZVr4ZOb11NRwjsN/D6rx7zlAJ6Ph14Wf2NBf/vO//if/oX/4aw4A1Ojvh1p9uo5BNT4toffi
iR9v5Jm6mUUfZ8/8+Q/5Zyaj4fXCdzYg9gCAJrQy9spo3mVK6d3CN3/4XJ6f2ymy1nw/Z2UK+anR
vt8no+H5wlfXJPYAgCa0LvbKdOr1E1uo3JXn5fb+vteIvvzeTraZ1nWCBgAQXpk6vV0Renm69NfJ
aHh8qECdjIZ5qjjH6G8L3/wmv+/bch0bEXsAQGglkG5WPB+Xp2xz5F0ufOcAJqNhHt37WxnJm5dH
/W42DT6xBwCE9Uzo5WfhTprc0HgbZduV/JjdxyX/+YtNg0/sAQAhPRN673dZ9NC0/GzeZDTMz/G9
X/JHTYPvqXN5vxN7AEA4JYTyYoz50Hsoi0euunDN5X3uFHxiDwCI6HrFytaTNu//t8wTwfe6bCPz
JLEHAIQyqMarjj57X+cxZPv0RPC9K9u2rCT2AIAwynN6H5Zcz69dmbpdpbz/35d8+7LsIbiUs3EB
gEiWBd2nTbdWGVTj/PMb72m3RB5J/Dpzpu5OZ93mRSUlaGdHLl+U6z5Z+A/EHgAQRZnOnN80+Us5
g3ZT80G1renv8TjaOKjGn/LzhDuOMp6ViJxdfPImn/e77Ig307gAQOfNnHk772zX0bSavU0p/WtQ
jbc6DSN9G93L+wIu2zZm2fWLPQAghPMl26zk6du2rrx9vc1pGFNlZPDz3JePli3WEHsAQATLRrpa
u2lysfFpGHOWXd/F/Bc8swcAdFoZzZof1fvYxDFok9HwLwtfXKGskD0pr3fLf+rxfV+uWlzxlLyN
zKAaf5z7vY/mn90zsgcAdN1aI1z7lmMzT7eWY8/+I6V0t+ItvNlhdG/Zc3o/TeWKPQCgs8ro2fwK
3EZG9XZR3s9JWR28zMKzdusom0TPP7v3dvYYNbEHAHTZskhq5ebJZVXwqhHHXfb0W3a9p9P/IfYA
gC47nXvvX1p+9u3CPnjF1nv6lZW5D3NfFnsAQLeVqcr5KdxVMdUKDe75Nx+4b6f/Q+wBAF21bAVr
m0f1mrQQuYNq/Hh/xB4A0FULz7ktOy6sJ5ZF7uP9EXsAQFfNj+yt2tqkNZ7YYmX+mbuNlNW+87+H
2AMAOu3V3Ju/7cDFLNsTMK0YmdvU/PU/3h+xBwB01dHc+27V3nrzykkfq07SqGP6eT4YH1f4Oi4N
AOicspnyvFaO7JVVw3lE78PCN795aHIV8S8dGfIEALql6VG2ZbHX1LYm301XuK7hVXmdrLGH3kVN
W7Isbbpf8m8+qMYL3wAA2Fbbjiur0R81/375aLdl59tuYyEYc5xOn9lr/eoVAKAzVp3/ys9y6C07
7q1W09iLWt8AwP7piqflZ/Te7yP00swCjdvZYzUAAHbQ11MsnvKl9FZeiHHd4LFp875OY+/miRUi
AACbWLpQIIjfVlxGXnH7j4WvpvRpMhqeLny1GQuLViaj4e3syB4AQB32MbK3rF0WYqduk9HwYtVv
WbaDmZ8pfTuoxqd7OsZt6fU/PrNXhhI/L3wXAGAzd/uYolzxZyyNnT06W3JkWXZV9tpr2vyf8bhQ
ZvYEjb4eHAwA1Odqj/dyfjeRVefO7kUJ0GXHob3Y032Zv/7HhTJiDwCo0z57Yn7V70FjL30LvqsV
s6WP07kLX63X/ObNj1Pd32OvbH647M0BAKzjbs+bKc8/t3e04hi1fdv7dO6gGi8L3Z9jb/omFn4M
AGA9dZ0Esa5lC0HWPc6sMSV4l92LJqdzl133YuyVocdlJQoA8JSH0hF7MxkNWxl76ceq3WUnlDU1
nTu/QXP+PBZjr1hWogAATzlUP3ya+79P97TydR2rTsiodTq3TF2/nvvy92cnV8We0T0AYF0PB4y9
+QUheap0X5sYP6mMrP2+5Gfqns5ddr2rY68sGza6BwCs63LFvnf7sGz176oRtUO4mO53N6fO6dz5
7V4eZjdxXoi99GOeedkbAwCY9eWpUyWaViJzfir3zaAat+XZva9PxOfO07mDapx/76O5L/8UwEtj
r1j1xgAA2tQLy2YkDxag88pCkvkgTTVN5y67zp++tjL2yhv7uPANAIBvPq5YEbtX5T3Mr3xtzehe
sWrvvbfbvs8Vo3qf5/c6XBl7xbnpXABgiS9LnhU7pGWje61Zg/DEUWppm+nc8vPPjuql52KvvLFW
rGgBAFrl9ICLMhasOKbs9aAaL8TPoax4j6mMzm36Pi9WjOotjLQ+GXvpx7Lh9wvfAAD66v10w96W
WRZMH1YcJXYoq6Zz/7HudG75uX8sfGPF85N/+fPPPxe+uMygGl+u+I0BgP74fTIarpqOPLhBNc6j
Z+/m3kd+nu9kk5HI8jzcwjm7daw8LluuLAvQ++dOISnTt/dlcces31a9t7VjL62+gQBAP+QFGUtH
j9riiRj6NBkNO/9o2qAa3y45LeNuMhoui8dHz07jziofsBW6ANA/rQ+99PR6g7dl0KqzyvufD720
avp2aqPYS4IPAPqoE6E3VRYp/LbwjZTedTX4nphdffb5yY2mcef+0Dxf/8+FbwAAkfw6GQ07eYzq
oBrnkyTeLnyjY/H6ROitdR1bx1768YDh1ZJ5cQCg2/KK0bPZM1a7pjy/d7Ni6jPPUp63afuYeeX9
5/v/ZuGbGzyDuPE07qzyF+B4xZ4xAEA35X/Xj7sceunH83snS07XSGWk7GZQjRdW3LZB2S7mZkXo
3W1yTN1OI3uzyqaF50b5AKCz8mjeRVenbVd5ZoTvoYzwteZZvvKo3MWKptp4G5naYq+8uVflaJJl
8+MAQHt9LKF3H/EzemZKNJXRzLNDXn8Zzbt84j1u9axhrbE3VXZ2vnjizQIA7fC5RN7CMVsRrXFI
xN6jtwyWXaxYhDG1ctPk5zQSe1Ml+s6eefMAwP7lqLnqS+TNWnOBab4/l00eC7dmJz2Uc4i3/pwa
jb2pMnR6Vl7L5ssBgObdlci5avMq1H0oo2lXa8xCTu/ZdR2jfWWq9qSsczha+IGffSpTyzt9VnuJ
vVnl5p6U3a2P17hQAGA7X8rChMdX1OfxdlHOwL1Ys0em9/N2+noqxErzvCq9M428df+cs7pGXfce
e/PKqN/0Jryc+RUAWN99eX1dJ0T4obTI+Y67ityVe/9qh4GsL+V5wVpXBh889tp2UN8AAABdSURB
VAAA2mDmsbN1pljrdFeeD2xk+xexBwAwZ2bxxGlDewh/KVvBXDW5CCSJPQCAp5VFFaflmbvjLePv
Ye75yUYDb5bYAwDYwMx6g+kCjFVy0D0+Q3mw5ydTSv8foyEK3BaDSLAAAAAASUVORK5CYII=
"
          id="image458"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default LinkedinIcon;

import SvgIcon from '@mui/material/SvgIcon';
import { SxProps } from '@mui/material/styles';

interface ResumeIconProps {
  sx: SxProps;
}

const ResumeIcon = ({ sx }: ResumeIconProps) => (
  <SvgIcon sx={sx}>
    <svg
      version="1.1"
      id="svg1031"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      xmlns-xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-svg="http://www.w3.org/2000/svg"
    >
      <defs id="defs1035" />
      <g id="g1037">
        <image
          width="512"
          height="512"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0eNT6AAAW2HpUWHRSYXcgcHJvZmlsZSB0eXBl
IGV4aWYAAHjarZtrchw7roT/cxWzhOKbXA6fEXcHd/nzJatalnQkH9tjK6xu14skgEwkwLJZ//9/
2/yHP9nmy4SYS6opXfwJNVTX+FKu+087v+0Vzu/XH/cc/XDcvJ1wHPJ8+vtESfenfR1/Pej5tI1v
8d2DynhO9I8naniGL58e9AzkNSNNYT4Pqs+DvLtP2OcB7V7WlWrJ75fQ1/05Xwst91+jX2Pdy7PP
aJ//HTLWm5GD3rnlOcxv79M9Aa+/wfjGl8pv6wsXWp/4Hs4Rjj8zwSBf2entD9eZramGLy/67C37
lbde38xnbwX3XOI/GTm9fX553Nj4tVeO6d+NHMrzzX083oNtrzj6YH393XuWfdbMKlpImDo9i3ot
5Xzjus4QGroYppauzN/II/L5qfwUonoQCvMaV+dn2God7to22Gmb3Xadz2EHUwxuGZf54txw/hws
Prvqhpf/gn7sdhkfTl9w8jhuD969zcWeYes1zBmtMPK0XOosD7Pc8ts/5ndv2FtQsPYqb7ZiXs4d
0FqZ0es3l+ERux+jxmPg18/nP/Krx4NRVhZEKobt9yN6tD+YwB9Hey6MfN4YtHk+D8BEDB2ZjPV4
AK9ZH22yV3YuW4shCw5qTB0AuY4HbIxuMkkXgBe+AUkMzS3ZnktddBw2HIfM8EQEZRnfgDKcFUIk
fnIoxFCLPoYYY4o5llhjSz6FFFNKOYkUW/Y5mBxzyjmXXHMrvoQSSyq5lFJLq656SDPWVHMttdbW
GLPx5MbdjQta6677Hno0PfXcS6+9DcJnhBFHGnmUUUebbvoJf8w08yyzzrbsIpRWWHGllVdZdbVN
qG1vdthxp5132XW3N689bv3Hz294zT5ec8dTujC/eY2jOb8eYUUnUT7DYc4Ei8ezXEBAO/nsKjYE
J8/JZ1d1oCI6Jhnls2nlMTwYlnVx25fvjLs9Ks/9T34zOXzwm/tTzxm57jc990+/feW1Kb4bx2M3
CmXUy4M+zq/SXOEC1yd5CWTCaD7NHn0xfjMjvuwxSvBrFmfT7m2x4uRX834kP8tKfezU5+7DuVLt
LlivH7tdaU4Mb0LCuKP6XaoLM/WQXB08eRB6dsQZhl82upbWYD0zb+fjsiW5nktNriQfygKtpkTc
o9BYkHXL06IqkmUZTHlhs3sxgcNW1337aV5fGKZvImqzqDIDn7Pyq/TQdnZtX3OX4mUVGDe18y0N
4jjEhhEiCdL6VX2J2HkTYcvmXZvHzZpMrxdmgbH9uHazs+pp45q5EjGlbvzVFsYBInOV2RWUrhc8
nOGgsa+9mptETupr20F6Gd1xT9E8rjXjqnVfrTP9mHvVUdP2TPoC8bneeBTU1D2LTHXMM+x5lF87
yGhck5kX2cVHnmAr8bLxTDIsD46cCRvMuRLRtAhXgvAXJsfUao2Djx5MIl6jbzBAvQgnz6xuTyjX
veLv82fHxSwt71GAScx7ZtMdodEUtV+cvU9iRqJo17wclzEcWCAPltsdzA0pBvr1byKIz5XgpLx8
kJM8KZsA3Kl25p426XpAhtV2LUoQ22MjDCb4XWM2AhLrA8BWpysTULpITHliiHt3HJ0E7mbE0DDR
8mmB4DXwRlqYL0bQQUh0cn8qLcac+wq1AjDC8YAUEZZ3bKPPPsJOizn6MrPtPTMQCxdgrdYA5Fmh
GTsHgNZARAZSqB1AfHU4a6AhWaojZNAoNQo3sa2NpQjBZUNtNkcINEdWbVbLDrJchUdMqJZkWBAg
lok1MF4h10TIEsJ9HCRaJu2ueFx7XZL7zMpus6sVePfuKWq6BA1sMxrAwEtoqVG2VdTM7k7g2lQj
a2SQ3YIAtfPAohh7E4YrpePNBSURg+eRJfLh9NTzgLy6QHzVOMfMxO/PINJrW6yVa/xGeC0PkFvb
TVTNcHA7k5y+tkGU+7mE1gPWGYwWuXpvuAL+3+gJWLzHncsiq61Y0R+QCg/hgZwkNY15EZ8YhuQR
61wuYz6DGfq0/c4HNezaI2hk1JCS0MgRl9uw8MtPkWi+hWIRFFkLFLpaUGCEda1Vy+2w0g8aXqhw
Rl92u9CZoilXV8XEcY268dzK+8dJwPqcxuvPBZl03DHALgbTkqIB6A7IqNt1Db5/gz5RPDKZAEqy
a6IFchrjArYjwfZikWHn7GZDpHiVdBMAiZV1G5+zwt2ONVaM3vFQqDAZfiKvYMYA1uIGVC6JpUcp
hrQzKIw2uSlsrhtMjuAVCXIQCgYqARFPEgdmncTL2ipp+J7xGACbuHEGbZjJ1iQDYAvIFKXLRZgk
ebfmCA7qZFEjE36EBwQAXVnSBTHjIVt0QoOQDWFeyZhE4GorTwRqTNcuhAuxFvZwR/KgVhLqZDt0
hCeToi3gFy5YqLKBdGim9wm9MqWKTOGp5LQafU2rKiofcJLO3Rv4kAYP/MCDAHjgZxbk9H1uZDBN
CltJ06miDTqwsWCmKNEwkAcwyEayEc7mogpKsMLqO56bUHsgL5Hu9sGv4ugg+BN+MyReooGuysog
YM/Imm9fdD+m/w5V70BFIn+BxfxL3tp4DbaEFp58HZijF/sK1SwKagXpsZoYWiYJNOactitjQgIA
wqG/0AzoN6oHP7mUFLUAXoZkCboInSJyDpMgwRI0wvNA9mQ5eBTGToWYJF1OpGTgmQKAv4FZbXqT
Mcz1yqRWcZuHVFEjfSKzKogCrJ/Ovp1MIBFaQ8aASwkJJ50IssaJD3Bj8vAPMRBavq1++4dpkFzI
vinc/AmrWOCsUErVzZZyaejSAHiKwyNGimIJr1uuxQxIWcjBkhqxB4opJawEo1zot6RiBGSSWt1r
tdOdaZjWIOBlqdRgUasyk+rcMz3QtQlAJBd0RIJ1iHM/R+9wBxir5HuwzhQ8WpHIRhpYnk86spJm
4ITpUpWPdI1aCK+6iE9qux4B+boaua2I0+yGjLARbhfrUGbdjojE0C8LyZeMPCISDCAjJbTeyUjM
+q2QbHB93wWO2ddA+0F7LiIcBvm8DKNcRoyXge8QKxs7pin8zXZ4ehXsJ6G6JS2AC0IUn8MUEKnr
WA6aQEfBkBEJ1CqFVyaLnxtqXYckmAGkdRL5FYKS8z5Zl+TIv56xnpGMFrOPan4NRiZgFR0RspOY
+cxl6TcLXinfGvvjY0jZ7x5zDjyTeKYgkTTOo1CxkAR+Qb7lidKClt05U1URARGNhyCeYohWT24n
hmCmkApyhyqS9GbJFkL5iNC1MGFVxiWF4PDMuyaj1g2nIlVf3y8+ovR5p4DQF0sQq/wiDgAZuIFY
Wcp8O2nEjPd5pdz7Cqox1CTaaoDfgdCFNVXh3tQKW1zrxQLJUVYpNxrQDiIqGgTBivQk0++Cidfy
JKBWVFTh1FVzJrIm3ARTYmkbwB9MWdR3mKiRzuwstyNDsTYeA6QdNLdCdJBQV6bKRssHMETw4/Tu
UC39Vi1wOimHtHVBbArJjm6ZDOCl9Cqqse0Y+q3bMKWD5NqgaK+JSj974jBcd8IgDSNRWzc2D4fY
9cQ54QOzrN3dpfSdJj7CzXCYilJ4APtpxTwI2Sjjw99RaCDmzRVQ0A7YjQWiYXFkENmm52wHpkFr
eLkmSz64Ra1EArYoaqAAtTFbEmOwIxi0GQlel5ITKNNTpyYgaEk4JIuyLxIR1S2ZgSsy1NJIIJnU
9lSn1OyjEyvmk1w+6771soIcgLzp5eI2aCCvYGfSGUohIjYoo1hGT+gjKmYEOjUJDvTSfTEiT0Ji
Otm7i0ob1bUc7iEFdbciaRy6R9YHKOYGFuV6dDeWAlr/HNrjPuVumUBOoqAkQSFozixZ4jkO9efN
06Fksu02CWMdakBZ75sbD9+8iKRRBt9EAhYhknXdRBIPkbR4GIBFm5sV70IcAgavFK38E7edkoFK
pu6HXhGSbYsUnmkqTZ2JMk2jw00yy/50kWcNBC3Ryx1DP/ZEkqhFFGVe8xOpMkORqsbX5PZPF3YC
FQkypzLAMnmds7jIi+f7mIxtKdVbIlvlKIVRpWUKmCMXWsKjjgvyV2mDNnKlF9gLY1txlYerSDgI
iRZFGncaJ3pD+ScxfUU95gM7faauXyUmpmRaKcRpwaAWkDNpDd4lDSPkPkKcl6dsJcmH7uIK5VpX
6TkOhRnMPwBsAVik7E7J2SEBwRGOGz0XxCCKHToIOBfpCgZANHjYMZUUMEpT7d5yIiMwT8iemlbD
F4xMtqIYJggCFcFO8+pWLTQoRe2uiiRBj6MMQ9rWwRjAFVTj/zLSqOZ0mlk69ZIYCpfyQBQNWdnH
pupjQ7mE4+yiO4cK3SrtxlAJr76aQ5f7QZnFLIkde4+Jfil5gMur4q9O9qrUERV1BReVQFmPigqO
BaN7I0XCWAm5fyWDfCAPIQymPe4s2c8rKdgbJapvKkEt2plcy2y03+F4Th6jXTtgqKhGBDM3sTm4
c5QK415U+QQk6HqCZ9SPZeL3nx7BPtSfUHHIzFQ7Hvi+qx7v9sXrglM97ld5uWLbFEkwuZEhUFhe
JeT9+I7x2097RV+1iszv94ocUbRR+A0l1xISZlOcBnF2WTgD8VW1W6TGLBF5untR4VB4MiqT6JrI
eiAeekI5O54Heig7IOTdslEbieFnpCZDQaMwwBIkT4pKlgD2KBsKXVQ76gk6UN3ScTNiJg4PO6nt
dMVi0IJ9tNgzSFuTUuWGfSF9j8/tT7VgIY5JaT1wtdoSg6otQRvLaKsSihMAqSlLjirbfQZfOSL3
vba3/EUOsdSdHi9OT5TvIgSQqWeTANspG7Iq9yWWbRtk0SjTLslX6GsR1CivtuCwEeui3KqVGY0k
PYBgrtp4uTs7imyi+Likn1b0ty3DT58wLxXSHRNqg5usdPAuKGy8y9/7vPpGd754LkFIUbVtiMQm
hUc44cGj1T5UP7f8iPk/65SYj62Sbzsl2yHk7hIMszS+qO7fMI2SBsnO/OQ0dibLszLK9nLaYCg6
plWfjg/en+m1EvMPGK/64GFaT9j+KvyNf+D/Hb7fCOBrfniDv/F/Cf7mj1rFX6zO/MLy/5UdZR3z
N9hR1jF/gx110vy2edqXBBnMVwRZ44Iarc9kyKa2D4/3VNIIg77C5fy1UIibFN3JcKha65upVuqm
wWQqwX1Bk7XAJTOqrWbjXXnDKyTrWzQH6ZZRSfOHQOqRe8lcaopLTx4gVkTbadKrUlyOATZmXt6r
6D65W1s+qO7TO7pVb/FoS6Rfk71g9BFPDW3/ZXR17r2aVgNRSI289lHCyJpfnvw3c+chZB3QD3+V
oeY4ddbRklD5afH99GY0LLLW8mXKvdsZKhY0qUuHzY9mpVi1EBmL7POpTSiATrVyyvSPD3jdbyTg
HnsdGfzDYHcJL5vbzxZ/b+/nbvP17XcH4DFauJXsY7RvLG6OyX9Y/Ge3fzbaYzI1F6DaY/JPFj9N
1O9s/uH2HzY3t80Is2M1VR5vdv9o9TebL0r3Bz+xazcEAW2nATsRVriSvwoCmVoYIarNDOGtBQQi
xSnCoWonT+8EIc/1JtCgLND7QUXtTG2LkZrTyJWMd2ON3EBKp8hV22sd4DcgGz5n1s+J1fxpZv2c
WM3f2YNoSpBfZ1YKEFKnqmPbUmxF5JVVAJTc+gUzqUFLuowrRR7sDeVADlfFRdMxwOwhpOrQUG5o
u6d1Z4m6C21mUVAqXwYHy4JUh7bp6/CTit2ZcY2E9+Cx0796ZWOB4Leyycd0tANaLHQWZG2jOMKh
YGEwm0Ddqn3frZ6GuvtnQXOr8oVtF1hrvjOfGVJ0K220lyvD1xAsD7qon522S7QBqh4OVsrUKsHH
VPWSEteiPiPFjMlzLCFZZubZvvs/qwXM1+nu97Od+Tv7xiuZDDQvmwpad5TpwKGPHYPgxll7StSk
CG6VcjmoNZMq/pxkSccYlFNqc3mIxoC89GxdxnYIbUAld/dTID/nyt2qoKDNd882n31S7fZCs2fv
0jjt31MLaLdidrLl1msfQzT3k1sPQd8jP+OafQ/LoAteg0X1VOaSiFPtX1FFasqfbvtx12uqkjXP
cOrajHCT25kt4wKOu7dypqq+7KfJvt1r3t1cfsFW307b/B1TKx19nP0fGVoWM3/D0LKV+RuG1r3m
bxiaes4ZveDSgAioIlXHTr2Xw4op6sWWFezg7+Uhq7K4QTtGS1rRNUrXnjxUmQSWbIQWat9E0XfD
OyWX9G7Dvre0Yj5bWvvDftfJNx9Pq8N+roBVhvZByWgjXz1vElLWztfTktVm68/a9+ar/v2ftO/N
0yajAD8aaKoT1vROYVJyVKXfKOYp+0b25EuiKuqtk6gWu1UGaTC29o5sdhllI67Jt069SGfB3w4P
ZyeKpH/rj3xv0MSJxNA283kpYydMNIwaZz0Utc397kiUe5do1Qp/MVH/7a3xVsD3qNS07wfVkOep
F2I/j6xtmpYY5DXd123/uIt67RkOdz4DqtWc6t0EloZ09uzw3JP9cqq613x1879b6p/TNu/m/Ydm
vqdt3s/7T4z8GtH8T0aOegtBqjdEM3McIKDmpFeTtCdetHGQ1WdW2yYD23wpzlPLFjnnXSNUF7oB
xQjACU6lUBOfN4HsDyA9+8gFOuw3mrTdD1byBzC5/gKTTpuDph9XOC7CBszDTUGqClLXW5crXt/t
ipv+YVec+fzivvjnXXGDMISw+B3qzPjHkfg53dTi87WpjEsIxdaKt1UvmQSQuWxV4/UWZj0mgtZI
aqL+jzJDRZQtZYZoqPdLYVJmI8eK9PE7hMXdWco+obHWUvnY5+nvGe1frfXZ0t9/ovq5Qe8QBL2t
5kJwtsYazLq0p30Vjx/RkXPsjDKsCZpJnTVQBfRRn5errhZaLrvWGijfkLcq4spME87WzvRcI1In
DCQoCSJxi6cwmiXZMsR7emu0RCWUBtdrj332cl5a0XulVrqzGeqWipDvb+TP1D+39n5SYrz6e3fT
93OR8ssFyLv6w3xVY3woQj5XKN909sz7AqR5HsK67wLNxxLR7HeB1uop0OqlDcbzsl9DdHs3E9cq
kZuu9/lyRY/uehXM40ECRWFiDcTV3R6xXq8cpaeHMvOp61oDynnq5Qmkhnaz9C7MKeTv3XJHDv64
55fnqebB3J1hoYuz33VX1TzJuWzURuk4GDiRqbO/d9vvolivxZ2iWNUwa3yK4nuz79Pw5t34/XQF
iJmgoj4VFfUOZlrnfQEppZ/M3vzS9OHP61T106qqj1T16rWNt9ZPpsr+8vaz27f7q3tzXnGv5ene
tPPuh6SYfbopAN0QG1D//SpirXc74ieP8J9Nx13HeJSicIU6A8f49rPlv7T76+b9zu7mZbnr2rfl
fsnqav+8t7v+u9CP29uFpllW72CM0T2ixnG7vVCkCltmqbd0vHb57A6IGLWY4dNGLBXTSC8Tni2g
gdwT9c5jC8jYcsSh9gNiVOxc4/6/QVY9QYnN9YH2zPvM8yO5fMotr+zzZWq6E4/5pcyz9Lof6Viv
InkJOIgPdC4tR3tqZFawdhU0+rjSu1fuSSI9qDinGo16Wb5TxENRLumN+6Jp+VXw6FJtnTqBZJz+
D4FShoVUXUvO640xHzP5OVOKkzZQhR6Gx7Sl1BRLCqE6n+D8HJL+QwyVs97QnM9b9EGO/uW36L99
q/6rz1EbRQMg1H8CrBaqUp+hzqmtyIz2V00dKMPLwEY2+6bcaWe0ddYYbGeKVdVBUC2yXNA7V5dH
dEQMoK2jpI1y6p3qWwp1JXIbXgvbu01mwh9OjS60il9DvbHclWVbqKMT4sHqRU0X7/+LgM2b/i+C
XuxTLjB3Z0avEcxq/gvVJ3uvnJ49dAAAAYRpQ0NQSUNDIHByb2ZpbGUAAHicfZE9SMNAHMVfW8UP
KqJWUHHIUJ3soiKOtQpFqBBqhVYdTC79giYNSYqLo+BacPBjserg4qyrg6sgCH6AODs4KbpIif9L
Ci1iPDjux7t7j7t3gL9WYqrZFgVUzTKS8ZiQzqwKHa/oQj/6MIxBiZn6nCgm4Dm+7uHj612EZ3mf
+3P0KFmTAT6BOMp0wyLeIJ7ZtHTO+8QhVpAU4nPiCYMuSPzIddnlN855h/08M2SkkvPEIWIh38Jy
C7OCoRJPE4cVVaN8f9plhfMWZ7VUYY178hcGs9rKMtdpjiKORSxBhAAZFRRRgoUIrRopJpK0H/Pw
jzh+kVwyuYpg5FhAGSokxw/+B7+7NXNTk25SMAa0v9j2xxjQsQvUq7b9fWzb9RMg8AxcaU1/uQbM
fpJebWrhI6B3G7i4bmryHnC5Aww96ZIhOVKApj+XA97P6JsywMAt0L3m9tbYx+kDkKKuEjfAwSEw
nqfsdY93d7b29u+ZRn8/fsdyqx+xJogAAA14aVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8P3hw
YWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/Pgo8eDp4bXBt
ZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA0LjQuMC1FeGl2
MiI+CiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRm
LXN5bnRheC1ucyMiPgogIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICB4bWxuczp4
bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIKICAgIHhtbG5zOnN0RXZ0PSJo
dHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VFdmVudCMiCiAgICB4bWxu
czpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iCiAgICB4bWxuczpHSU1QPSJo
dHRwOi8vd3d3LmdpbXAub3JnL3htcC8iCiAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUu
Y29tL3RpZmYvMS4wLyIKICAgIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4w
LyIKICAgeG1wTU06RG9jdW1lbnRJRD0iZ2ltcDpkb2NpZDpnaW1wOjAwNjNlODA0LTYwY2QtNGVm
ZC05YjA2LWE1YzM4N2ZkOTM4MyIKICAgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpiMjEzYWVh
MS1hZDU5LTQ4N2QtYmI0Ni02OGM0M2FlNzBkMWEiCiAgIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJ
RD0ieG1wLmRpZDozMmFiODU0MC1kMGY2LTQ1ODEtYTBiYi1lYjBkMTQ3NjZlZWQiCiAgIGRjOkZv
cm1hdD0iaW1hZ2UvcG5nIgogICBHSU1QOkFQST0iMi4wIgogICBHSU1QOlBsYXRmb3JtPSJMaW51
eCIKICAgR0lNUDpUaW1lU3RhbXA9IjE3MjY2OTI1NDk1MjcxODciCiAgIEdJTVA6VmVyc2lvbj0i
Mi4xMC4zNiIKICAgdGlmZjpPcmllbnRhdGlvbj0iMSIKICAgeG1wOkNyZWF0b3JUb29sPSJHSU1Q
IDIuMTAiCiAgIHhtcDpNZXRhZGF0YURhdGU9IjIwMjQ6MDk6MThUMTY6NDk6MDktMDQ6MDAiCiAg
IHhtcDpNb2RpZnlEYXRlPSIyMDI0OjA5OjE4VDE2OjQ5OjA5LTA0OjAwIj4KICAgPHhtcE1NOkhp
c3Rvcnk+CiAgICA8cmRmOlNlcT4KICAgICA8cmRmOmxpCiAgICAgIHN0RXZ0OmFjdGlvbj0ic2F2
ZWQiCiAgICAgIHN0RXZ0OmNoYW5nZWQ9Ii8iCiAgICAgIHN0RXZ0Omluc3RhbmNlSUQ9InhtcC5p
aWQ6MTM2YjA3ZTktNDEwZS00MDQ5LTkwYjgtOTQyZTgyM2JjZmJmIgogICAgICBzdEV2dDpzb2Z0
d2FyZUFnZW50PSJHaW1wIDIuMTAgKExpbnV4KSIKICAgICAgc3RFdnQ6d2hlbj0iMjAyNC0wOS0x
OFQxNjo0OTowOS0wNDowMCIvPgogICAgPC9yZGY6U2VxPgogICA8L3htcE1NOkhpc3Rvcnk+CiAg
PC9yZGY6RGVzY3JpcHRpb24+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgogICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgCiAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAKICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgIAogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgCiAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgIAogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIAogICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAKICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
IAogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgCiAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgIAogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIAogICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg
ICAgICAgICAgICAKICAgICAgICAgICAgICAgICAgICAgICAgICAgCjw/eHBhY2tldCBlbmQ9Inci
Pz6PWfjcAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH6AkS
FDEJZW16kAAACntJREFUeNrt3VFy1DoURVFEaSIajcbs0Xgo5g+KghQJsdPSPWv9v0eitHS31R1o
3/jTeVwWATY0Zit1BlX5flhStwSGPrDwuSQCEACGPiAC4C7fDX8A5xUCIGMj2UyACEAA2EAAzjAE
gI0D4CxDANgwAM40BICNAuBsQwDYIADOOAQAACIAAWBTADjvEAAAiAAEgI0A4OxDAAAgAhAAXvwA
zkEEAAAiAAEAgAhAAHixAzgXcQMAgAhAAAAgAhAAACIABACACEAAACACEAAAiAAEAAAiAAEAgAhA
AAAgAhAAAIgABAAAIgABAIAIQAAAIAIQAACIAAQAACIAAQCACEAAACACEAAAiAAEAAAiAAEAgAhA
AAAgAgQAAIgAAQAAIkAAAIAIEAAAIAIEAAAiAAEAgAhAAAAgAhAAAIgABAAAIgABAIAIQAAAIAIQ
AACIAD6vWwK2MWZzEMHGEXDHHkYAYNjf8v8VBCACBAAUHfrv/TPFAIgAAQCFh74YABGADwHywmG7
8gGw+tcHFSIAAYDB7+sFEYAAwOD39YMIQABQYvgLGUAECAA89YsaQAQIAAxIgQOIAAGAoSh2QAQg
ADAIfe8gAhAAGIDWAEQAAgCDb721sB4gAgQAhr91AUSAAMCQsz6ACBAAGG7WCRABAgBDDUAECAAM
f2sGiAABgEFm7QARIABABIAIQABgeFlHEAEIAAwtQAQgAEBQgQhAAGBYWVcQAQIADClABAgAQGCB
CBAAGE4AIkAAAEILRIAAwFACEAECABBcIAIEAIYRgAgQAAAgAgQA8IubFxABAgBDCEAECAAQYIAI
EAAAIAIEAJ4+AUSAAAAAESAAYDduYkAECAAAEAECAE+dACJAAACACBAAACACBAAAiAABADvxmQwQ
AQIAAESAAAAAESAAAEAECAAAEAECAAB4S7cEAIvxmyC4AQAABAAAIAAAAAEAAAgA2FTgv0sOCAAA
QAAAAAIAABAA3MR7zgACAAAQAMBXchsDCAAAQADgyRMAAQAiDEAAAAACAE+gAAgAEF8AAgAABAB4
EgUQAIDoAgQABhIAAgAQW4AAwGACQAAAIgsQABhQWFtAAGBQASAAQFQBCAAMLGsJIAAwuAAQACCi
AAQABpi1AxAAGGTWDEAAYKBZKwABgMFmjQAEAAactQEQABh01gQQAGDgWQtAAIDBZw0AAQD/HoCp
Q9DwBwQAQiBoGCZHDyAAIDICDH5gY90S8PiAHLMZ/ABuAEgMgSpD0/AHBAAEDU/v9QPFeAuA10XA
Dm8NGPqAAICHhutqIWDoAwIAgm4FDH5AAEBADBj4gACAxWPgs1Fg2AP8xu9nA8B7Ffp7TfwaIAAE
EgAAIAAAAAEAAAgAAEAAAAACAAAQAACAAAAABAAAIAAAAAEAAAgAAEAAAAACAAAQAACAAAAABAAA
IAAAQAAAAAIAABAAAIAAAAAEAAAgAAAAAQAALKVbArYyZvvv//Y8LgsIIADYfaA/+WeJBUAAwEZD
/iu+ZnEACAAoMOzv+B5FASAAMPCtgygABAAGvrUSA4AAwNC3hoIAEAAY+tnrKwQAAYChH77mYgAQ
ABj8bgUABAAGv1sBAAGAwe9WAEAAYPALAYAP8q8BpgwPw1/EAbgBMCxwGwC4AcDwx88YcAOAoYDb
AMANAIY/fvaAAMAAwGsAEAA4+FntteD1AAgAwx+vCwAB4JDH6wNAADjcARAAGP54rQACAAc6XjOA
AABEACAAcIgDIAAw/PEaAgQAIAIAAYBDGwABAAhKQADgsAZAAAAAAgB4gpslEACWAAAEAJ7SABAA
AIAAAAAEAAAgAAAAAQAszYdMQQAAAAIAABAAAIAAAAAEAAAgAAAAAQAACAAAQAAAAAIAABAAAIAA
AAAEAAAgAAAAAQAACAAAQAAAgAAAAAQAACAAAAABAAAIAABAAAAAAgAAEAAAgAAAAAQAACAAAAAB
AAAIAABAAAAAAgAAEAAAgAAAAAQAACTqlmBh53FZBADcAAAAAgAAEAAAgAAAAAQAACAAAEAAAAAC
AAAQAACAAAAABAAAIAAAAAEAAAgAAEAAAAACAAAQAACAAAAABAAAIAAAAAEAAAgAAEAAAIAAAAAE
AAAgAAAAAQAACAAAQAAAAAIAABAAAIAAAAAEAAAgAAAAAQAACAAAQAAAAAIAABAAAIAAAAAEAAAI
AABAAAAAAgAAEAAAgAAAAAQAACAAAAABAAAIAABAAAAAj+qWIMCYzSIAH3Iel0UQABj6QPIZIgYE
AAY/EHyuCAEBgMEPCAH25kOAhj+AM8cNADYhgNsANwAY/gDOIgGADQfgTBIA2GgAziYBAAAIABQ2
4IxCAGBjAc4qBAAAIABQ1IAzCwEAAAgAJQ3g7EIAAAACAAAQAACAANia99AAZxgCAAAQAACAAAAA
BAAAIAAAAAEAAAIAABAAAIAAAAAEAAAgAADgDedxWQQBgM0DgAAAAAQAALW4wRQA2EQACAAAQAAA
UI6bSwGAzQQ4rxAA2FQACAAAPKggALC5AOcTAgCbDHAuIQCw2QAQAIgAYIlzyFkkABABgPMHAYBN
CDh3WFi3BIU245jNYgAGPwJACAAY/AgAIQBg8CMA8javIAAMfASAzQ1ALr8FAAACAAAQAACAAAAA
BAAAIAAAAAEAAAgAAEAAAAACAAAQAACAAAAABAAAIAAAAAEAAAgAAEAAAAACAAAEAAAgAAAAAQAA
CAAAQAAAAAIAABAAAIAAAAAEAAAgAAAAAQAACAAAQAAAAAIAABAAAIAAAAAEAAAIAABAAAAAAgAA
EAAAgAAAAAQAACAAAAABAAAIAABAAAAAAgAAEAAAgAAAAAQAACAAAAABAAD81C1BkDGbRYAHnMdl
ERAAGPiQvtcEAQIAgx+C96AQQABg8IMQAAGAwQ9CAF7KbwEY/oD9igDAYQLYtwgAHCKA/YsAwOEB
2McIABwagP2MAMBhAdjXCAAAQADgKQGwvxEAOBwAEAAAQt8iIAAAAAHgqQCw30EAAAACAAAQAJW4
DgRAAAAAAgCgIjd/CAAAQAAAAAIAABAAAIAAAAAEAAAgAABAAACwjvO4LAICAAAQAJ4EAEAAAAAC
AGBzbv0QAACAAPBEAAACAEDsgwBwMACAAAAQ+SAAHBCAvQ0CwEEB2NMgAABAAOCJAbCXEQA4OAB7
GAGAAwSwd6miW4ICB8mYzWKAwQ9uABwsgD0KbgDcBgAGPwgAIQAY/AgASxBwAIkBMPRBADiYAMCH
AAFAAAAAAgAAEAAAgAAAAAQAACAAAAABAAAIAABAAAAAAgAAEAAAgAAAAAQAACAAAAABAAAIAABA
AACAAAAABAAAIAAAAAEAAAgAAEAAAAACAAAQAACAAAAABAAAIAAAAAEAAAgAAEAAAAACAAD4q1bu
OzqPy48VgNuNWWpmugEAgEACAAACtZLflbcBALhTset/NwAA4AbALQAAJDz9uwEAADcAbgEAIOHp
3w0AALgBcAsAAAlP/xkBIAIAMPxDA0AEAGD4hwaACADA8A8NABEAgOEfGgBCAIDgwS8ARAAAocNf
AIgBAENfACAGAAz9FD8A7D46t7J7MZEAAAAASUVORK5CYII=
"
          id="image1039"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default ResumeIcon;
